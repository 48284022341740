
export default {
  name: 'Breadcrumbs',
  computed: {
    currentBC() {
      if (this.$route.path === '/'){
        return null
      }

      let r = this.$route.path.toLowerCase().replace(/\/+$/, '')

      if (Object.keys(this.bcOptionsNormalized).includes(r)) {
        return this.bcOptionsNormalized[r]
      }

      return null
    },
    bcOptionsNormalized () {
      return Object.keys(this.bcOptions).reduce((res, k) => {
        res[k.toLowerCase()] = this.bcOptions[k]
        return res
      }, {})
    }
  },
  data () {
    return {
      bcOptions: {
        '/about' : [
          {name: 'О компании', href: '', active: false}
        ],
        '/about/contacts' : [
          {name: 'О компании', href: '/about', active: true},
          {name: 'Контакты', href: '', active: false},
        ],
        '/about/news' : [
          {name: 'О компании', href: '/about', active: true},
          {name: 'Новости', href: '', active: false},
        ],
        '/about/news/all' : [
          {name: 'О компании', href: '/about', active: true},
          {name: 'Новости', href: '/about/news', active: true},
          {name: 'Архив', href: '', active: false},
        ],
        '/about/vacancy' : [
          {name: 'О компании', href: '/about', active: true},
          {name: 'Вакансии', href: '', active: false},
        ],

        '/order' : [
          {name: 'Заказ и доставка', href: '', active: false}
        ],
        '/order/shipping' : [
          {name: 'Заказ и доставка', href: '/order', active: true},
          {name: 'Условия доставки', href: '', active: false},
        ],
        '/order/distributors' : [
          {name: 'Заказ и доставка', href: '/order', active: true},
          {name: 'Дистрибьюторы', href: '', active: false},
        ],
        '/order/pay' : [
          {name: 'Заказ и доставка', href: '/order', active: true},
          {name: 'Способы оплаты', href: '', active: false},
        ],
        '/order/return' : [
          {name: 'Заказ и доставка', href: '/order', active: true},
          {name: 'Условия возврата', href: '', active: false},
        ],
        '/order/sample-requirements' : [
          {name: 'Заказ и доставка', href: '/order', active: true},
          {name: 'Требования к биологическим материалам', href: '', active: false},
        ],
        '/order/special-offers' : [
          {name: 'Заказ и доставка', href: '/order', active: true},
          {name: 'Специальные предложения', href: '', active: false},
        ],

        '/products' : [
          {name: 'Наборы и&nbsp;реактивы', href: '', active: false}
        ],
        '/products/pricelist' : [
          {name: 'Наборы и&nbsp;реактивы', href: '/products', active: true},
          {name: 'Прайс-лист', href: '', active: false}
        ],
        '/products/antibodies' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Антитела', href: '', active: false}
        ],
        '/products/antibodies/anti-gfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Антитела', href: '/products/antibodies', active: true},
          {name: 'Anti-GFP', href: '', active: false},
        ],
        '/products/antibodies/anti-tag-cgyfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Антитела', href: '/products/antibodies', active: true},
          {name: 'Anti-Tag(CGY)FP', href: '', active: false},
        ],
        '/products/antibodies/anti-trfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Антитела', href: '/products/antibodies', active: true},
          {name: 'Anti-tRFP', href: '', active: false},
        ],
        '/products/antibodies/anti-turbo-gfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Антитела', href: '/products/antibodies', active: true},
          {name: 'Anti-TurboGFP(d)', href: '', active: false},
        ],
        '/products/antibodies/anti-turbo-yfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Антитела', href: '/products/antibodies', active: true},
          {name: 'Anti-TurboYFP', href: '', active: false},
        ],
        '/products/antibodies/anti-killer-red' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Антитела', href: '/products/antibodies', active: true},
          {name: 'Anti-KillerRed', href: '', active: false},
        ],

        '/products/antibodies/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Антитела', href: '/products/antibodies', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/cdna' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '', active: false}
        ],
        '/products/cdna/synthesis' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Синтез', href: '', active: false}
        ],
        '/products/cdna/synthesis/mmlv' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Синтез', href: '/products/cdna/synthesis', active: true},
          {name: 'MMLV', href: '', active: false}
        ],
        '/products/cdna/synthesis/mmlv/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Синтез', href: '/products/cdna/synthesis', active: true},
          {name: 'MMLV', href: '/products/cdna/synthesis/mmlv', active: true},
          {name: 'Цитирование', href: '', active: false}
        ],
        '/products/cdna/synthesis/mint' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Синтез', href: '/products/cdna/synthesis', active: true},
          {name: 'Mint', href: '', active: false},
        ],
        '/products/cdna/synthesis/mint/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Синтез', href: '/products/cdna/synthesis', active: true},
          {name: 'Mint', href: '/products/cdna/synthesis/mint', active: true},
          {name: 'Цитирование', href: '', active: false}
        ],
        '/products/cdna/synthesis/onetube' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Синтез', href: '/products/cdna/synthesis', active: true},
          {name: 'OneTube', href: '', active: false}
        ],
      '/products/cdna/synthesis/onetube/citing' : [
        {name: 'Наборы и реактивы', href: '/products', active: true},
        {name: 'Работа с кДНК', href: '/products/cdna', active: true},
        {name: 'Синтез', href: '/products/cdna/synthesis', active: true},
        {name: 'OneTube', href: '/products/cdna/synthesis/onetube', active: true},
        {name: 'Цитирование', href: '', active: false}
      ],
        '/products/cdna/synthesis/magnus' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Синтез', href: '/products/cdna/synthesis', active: true},
          {name: 'Magnus', href: '', active: false}
        ],
        '/products/cdna/normalization' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Нормализация', href: '', active: false}
        ],
        '/products/cdna/normalization/dsn' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Нормализация', href: '/products/cdna/normalization', active: true},
          {name: 'DSN', href: '', active: false}
        ],
        '/products/cdna/normalization/dsn/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Нормализация', href: '/products/cdna/normalization', active: true},
          {name: 'DSN', href: '/products/cdna/normalization/dsn', active: true},
          {name: 'Цитирование', href: '', active: false}
        ],
        '/products/cdna/normalization/trimmer2/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Нормализация', href: '/products/cdna/normalization', active: true},
          {name: 'Trimmer-2', href: '/products/cdna/normalization/trimmer2', active: true},
          {name: 'Цитирование', href: '', active: false}
        ],
        '/products/cdna/normalization/trimmer2' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'Нормализация', href: '/products/cdna/normalization', active: true},
          {name: 'Trimmer-2', href: '', active: false}
        ],
        '/products/cdna/race' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'RACE', href: '', active: false}
        ],
        '/products/cdna/race/mint-race-cdna' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'RACE', href: '/products/cdna/race', active: true},
          {name: 'Mint RACE cDNA amplification set', href: '', active: false}
        ],
        '/products/cdna/race/mint-race-primers' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Работа с кДНК', href: '/products/cdna', active: true},
          {name: 'RACE', href: '/products/cdna/race', active: true},
          {name: 'Mint RACE primer set', href: '', active: false}
        ],

        '/products/cloning' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Клонирование ДНК', href: '', active: false}
        ],
        '/products/cloning/vectors' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Клонирование ДНК', href: '/products/cloning', active: true},
          {name: 'Векторы для клонирования', href: '', active: false},
        ],
        '/products/cloning/quick-ta' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Клонирование ДНК', href: '/products/cloning', active: true},
          {name: 'Набор Quick-TA kit', href: '', active: false},
        ],
        '/products/cloning/competent-cells' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Клонирование ДНК', href: '/products/cloning', active: true},
          {name: 'Компетентные клетки', href: '', active: false},
        ],
        '/products/cloning/competent-cells/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Клонирование ДНК', href: '/products/cloning', active: true},
          {name: 'Компетентные клетки', href: '/products/cloning/competent-cells', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/components' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Отдельные компоненты', href: '', active: false}
        ],
        '/products/practicum' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Практикум', href: '', active: false}
        ],
        '/products/lenti' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Лентивирусные частицы', href: '', active: false}
        ],
        '/products/practicum/topics' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Практикум', href: '/products/practicum', active: true},
          {name: 'Задачи', href: '', active: false}
        ],
        '/products/cell-lines' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Клеточные линии', href: '', active: false}
        ],
        '/products/mycoplasma' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выявления контаминации микоплазмой', href: '', active: false}
        ],
        '/products/mycoplasma/myco-report' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выявления контаминации микоплазмой', href: '/products/mycoplasma', active: true},
          {name: 'MycoReport', href: '', active: false},
        ],
        '/products/mycoplasma/myco-real-time' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выявления контаминации микоплазмой', href: '/products/mycoplasma', active: true},
          {name: 'Myco Real-Time', href: '', active: false},
        ],
        '/products/evaluation' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Оценка ДНК', href: '', active: false}
        ],
        '/products/evaluation/ladders' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Оценка ДНК', href: '/products/evaluation', active: true},
          {name: 'Маркеры длин ДНК', href: '', active: false},
        ],
        '/products/evaluation/quantum' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Оценка ДНК', href: '/products/evaluation', active: true},
          {name: 'QuantumDNA', href: '', active: false},
        ],
        '/products/evaluation/quantum/concentration' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Оценка ДНК', href: '/products/evaluation', active: true},
          {name: 'QuantumDNA', href: '/products/evaluation/quantum', active: true},
          {name: 'Определение пригодности образца для ПЦР', href: '', active: false},
        ],
        '/products/evaluation/quantum/fragmentation' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Оценка ДНК', href: '/products/evaluation', active: true},
          {name: 'QuantumDNA', href: '/products/evaluation/quantum', active: true},
          {name: 'Оценка степени фрагментации образцов ДНК', href: '', active: false},
        ],
        '/products/fp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '', active: false}
        ],
        '/products/fp/basic' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-bfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagBFP', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-bfp/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagBFP', href: '/products/fp/basic/tag-fps/tag-bfp', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-cfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagCFP', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-cfp/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagCFP', href: '/products/fp/basic/tag-fps/tag-cfp', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-gfp2' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagGFP2', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-gfp2/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagGFP2', href: '/products/fp/basic/tag-fps/tag-gfp2', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-yfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagYFP', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-yfp/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagYFP', href: '/products/fp/basic/tag-fps/tag-yfp', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-rfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagRFP', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/tag-rfp/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'TagRFP', href: '/products/fp/basic/tag-fps/tag-rfp', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/mkate2' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'mKate2', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/mkate2/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'mKate2', href: '/products/fp/basic/tag-fps/mkate2', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/fusion-red' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Фотоактивируемые белки', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'FusionRed', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/fusion-red/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'FusionRed', href: '/products/fp/basic/tag-fps/fusion-red', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/tag-fps/properties' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TagFPs', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'Сводная таблица свойств', href: '', active: false},
        ],

        '/products/fp/basic/turbo-colors' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-gfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboGFP', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-gfp/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboGFP', href: '/products/fp/basic/turbo-colors/turbo-gfp', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-yfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboYFP', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-yfp/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboYFP', href: '/products/fp/basic/turbo-colors/turbo-yfp', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-rfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboRFP', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-rfp/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboRFP', href: '/products/fp/basic/turbo-colors/turbo-rfp', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-fp602' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboFP602', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-fp602/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboFP602', href: '/products/fp/basic/turbo-colors/turbo-fp602', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-fp635' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboFP635', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/turbo-fp635/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/turbo-colors', active: true},
          {name: 'TurboFP635', href: '/products/fp/basic/turbo-colors/turbo-fp635', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/turbo-colors/properties' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа TurboColors', href: '/products/fp/basic/tag-fps', active: true},
          {name: 'Сводная таблица свойств', href: '', active: false},
        ],

        '/products/fp/basic/flim' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа FLIM', href: '', active: false},
        ],
        '/products/fp/basic/flim/bruslee' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа FLIM', href: '/products/fp/basic/flim', active: true},
          {name: 'BrUSLEE', href: '', active: false},
        ],
        '/products/fp/basic/flim/slim' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа FLIM', href: '/products/fp/basic/flim', active: true},
          {name: 'SLIM', href: '', active: false},
        ],
        '/products/fp/basic/flim/properties' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Группа FLIM', href: '/products/fp/basic/flim', active: true},
          {name: 'Сводная таблица свойств', href: '', active: false},
        ],

        '/products/fp/basic/whole-body-imaging' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Визуализация', href: '', active: false},
        ],
        '/products/fp/basic/whole-body-imaging/katushka2s' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Визуализация', href: '/products/fp/basic/whole-body-imaging', active: true},
          {name: 'Katushka2S', href: '', active: false},
        ],
        '/products/fp/basic/whole-body-imaging/katushka2s/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Визуализация', href: '/products/fp/basic/whole-body-imaging', active: true},
          {name: 'Katushka2S', href: '/products/fp/basic/whole-body-imaging/katushka2s', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/whole-body-imaging/turbo-fp650' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Визуализация', href: '/products/fp/basic/whole-body-imaging', active: true},
          {name: 'TurboFP650', href: '', active: false},
        ],
        '/products/fp/basic/whole-body-imaging/turbo-fp650/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Визуализация', href: '/products/fp/basic/whole-body-imaging', active: true},
          {name: 'TurboFP650', href: '/products/fp/basic/whole-body-imaging/turbo-fp650', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/whole-body-imaging/nirfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Визуализация', href: '/products/fp/basic/whole-body-imaging', active: true},
          {name: 'NirFP', href: '', active: false},
        ],
        '/products/fp/basic/whole-body-imaging/nirfp/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Визуализация', href: '/products/fp/basic/whole-body-imaging', active: true},
          {name: 'NirFP', href: '/products/fp/basic/whole-body-imaging/nirfp', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/whole-body-imaging/properties' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Визуализация', href: '/products/fp/basic/whole-body-imaging', active: true},
          {name: 'Сводная таблица свойств', href: '', active: false},
        ],

        '/products/fp/basic/photoactivatable' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Фотоактивируемые белки', href: '', active: false},
        ],
        '/products/fp/basic/photoactivatable/ps-cfp2' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Фотоактивируемые белки', href: '/products/fp/basic/photoactivatable', active: true},
          {name: 'PS-CFP2', href: '', active: false},
        ],
        '/products/fp/basic/photoactivatable/ps-cfp2/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Фотоактивируемые белки', href: '/products/fp/basic/photoactivatable', active: true},
          {name: 'PS-CFP2', href: '/products/fp/basic/photoactivatable/ps-cfp2', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/photoactivatable/kfp-red' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Фотоактивируемые белки', href: '/products/fp/basic/photoactivatable', active: true},
          {name: 'KFP-Red', href: '', active: false},
        ],
        '/products/fp/basic/photoactivatable/kfp-red/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Фотоактивируемые белки', href: '/products/fp/basic/photoactivatable', active: true},
          {name: 'KFP-Red', href: '/products/fp/basic/photoactivatable/kfp-red', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/basic/photoactivatable/pa-tag-rfp' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Фотоактивируемые белки', href: '/products/fp/basic/photoactivatable', active: true},
          {name: 'PA-TagRFP', href: '', active: false},
        ],
        '/products/fp/basic/photoactivatable/properties' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Фотоактивируемые белки', href: '/products/fp/basic/photoactivatable', active: true},
          {name: 'Сводная таблица свойств', href: '', active: false},
        ],

        '/products/fp/basic/previous' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Базовые', href: '/products/fp/basic', active: true},
          {name: 'Предыдущие версии', href: '', active: false},
        ],

        '/products/fp/biosensors' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Биосенсоры', href: '', active: false},
        ],
        '/products/fp/biosensors/hyper' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Биосенсоры', href: '/products/fp/biosensors', active: true},
          {name: 'HyPer', href: '', active: false},
        ],
        '/products/fp/biosensors/hyper/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Биосенсоры', href: '/products/fp/biosensors', active: true},
          {name: 'HyPer', href: '/products/fp/biosensors/hyper', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/biosensors/case12' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Биосенсоры', href: '/products/fp/biosensors', active: true},
          {name: 'Case12', href: '', active: false},
        ],
        '/products/fp/biosensors/case12/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Биосенсоры', href: '/products/fp/biosensors', active: true},
          {name: 'Case12', href: '/products/fp/biosensors/case12', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/biosensors/casper3-gr' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Биосенсоры', href: '/products/fp/biosensors', active: true},
          {name: 'Casper3-GR', href: '', active: false},
        ],
        '/products/fp/biosensors/casper3-gr/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Биосенсоры', href: '/products/fp/biosensors', active: true},
          {name: 'Casper3-GR', href: '/products/fp/biosensors/casper3-gr', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/biosensors/products-list' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Биосенсоры', href: '/products/fp/biosensors', active: true},
          {name: 'Список продуктов', href: '', active: false},
        ],

        '/products/fp/photosensibilizators' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '', active: false},
        ],
        '/products/fp/photosensibilizators/killer-red' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '/products/fp/photosensibilizators', active: true},
          {name: 'KillerRed', href: '', active: false},
        ],
        '/products/fp/photosensibilizators/killer-red/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '/products/fp/photosensibilizators', active: true},
          {name: 'KillerRed', href: '/products/fp/photosensibilizators/killer-red', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/photosensibilizators/arrest-red' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '/products/fp/photosensibilizators', active: true},
          {name: 'ArrestRed', href: '', active: false},
        ],
        '/products/fp/photosensibilizators/killer-orange' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '/products/fp/photosensibilizators', active: true},
          {name: 'KillerOrange', href: '', active: false},
        ],
        '/products/fp/photosensibilizators/killer-orange/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '/products/fp/photosensibilizators', active: true},
          {name: 'KillerOrange', href: '/products/fp/photosensibilizators/killer-orange', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/fp/photosensibilizators/products-list' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '/products/fp/photosensibilizators', active: true},
          {name: 'Список продуктов', href: '', active: false},
        ],

        '/products/fp/vectors' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Векторы', href: '', active: false},
        ],
        '/products/fp/vectors/organelle-labeling' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '/products/fp/vectors', active: true},
          {name: '<nobr>Для мечения белков и органелл</nobr>', href: '', active: false},
        ],
        '/products/fp/vectors/promoter-testing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Фотосенсибилизаторы', href: '/products/fp/vectors', active: true},
          {name: '<nobr>Для анализа активности промоторов</nobr>', href: '', active: false},
        ],

        '/products/fp/recombinants' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Флуоресцентные белки', href: '/products/fp', active: true},
          {name: 'Препараты рекомбинантных белков', href: '', active: false},
        ],


        '/products/isolation' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '', active: false}
        ],
        '/products/isolation/plasmid-kits' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'Плазмидная ДНК', href: '', active: false},
        ],
        '/products/isolation/extract-dna-ffpe' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'Геномная ДНК из FFPE', href: '', active: false},
        ],
        '/products/isolation/extract-dna-blood-and-cells' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'Суммарная ДНК из крови и клеток', href: '', active: false},
        ],
        '/products/isolation/cleanup' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'ДНК из агарозного геля и реакционных смесей', href: '', active: false},
        ],
        '/products/isolation/cleanup-pcr' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'ДНК из реакционных смесей', href: '', active: false},
        ],
        '/products/isolation/cleanup-gel' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'ДНК из агарозного геля', href: '', active: false},
        ],
        '/products/isolation/cleanmag' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'ДНК из реакционных смесей', href: '', active: false},
        ],
        '/products/isolation/cleanmag/magstand' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'ДНК из реакционных смесей', href: '/products/isolation/cleanmag', active: true},
          {name: '<nobr>Магнитные&nbsp;штативы</nobr>', href: '', active: false},
        ],
        '/products/isolation/intact-rna' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'Фиксатор РНК', href: '', active: false},
        ],
        '/products/isolation/ribocare-rnase-inhibitor' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'Ингибитор РНКаз', href: '', active: false},
        ],
        '/products/isolation/dnase-e' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'ДНКаза Е', href: '', active: false},
        ],
        '/products/isolation/extract-rna' : [
          {name: 'Наборы&nbspи&nbspреактивы', href: '/products', active: true},
          {name: 'Выделение&nbspнуклеиновых&nbspкислот', href: '/products/isolation', active: true},
          {name: 'Выделение&nbspсуммарной&nbspРНК', href: '', active: false},
        ],
        '/products/isolation/clean-rna' : [
          {name: 'Наборы&nbspи&nbspреактивы', href: '/products', active: true},
          {name: 'Выделение&nbspнуклеиновых&nbspкислот', href: '/products/isolation', active: true},
          {name: 'Очистка&nbspсуммарной&nbspРНК', href: '', active: false},
        ],
        '/products/isolation/rna-solo' : [
          {name: 'Наборы&nbspи&nbspреактивы', href: '/products', active: true},
          {name: 'Выделение&nbspнуклеиновых&nbspкислот', href: '/products/isolation', active: true},
          {name: 'РНК', href: '', active: false},
        ],
        '/products/isolation/bisquick' : [
          {name: 'Наборы&nbspи&nbspреактивы', href: '/products', active: true},
          {name: 'Выделение&nbspнуклеиновых&nbspкислот', href: '/products/isolation', active: true},
          {name: 'Бисульфитная модификация ДНК', href: '', active: false},
        ],
        '/products/isolation/rcf-rpm' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'Расчет RCF-RPM on-line', href: '', active: false},
        ],
        '/products/isolation/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Выделение нуклеиновых кислот', href: '/products/isolation', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/nomotech' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '', active: false},
        ],
        '/products/nomotech/insider' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'Инсайдер', href: '', active: false},
        ],
        '/products/nomotech/insider-fullras' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'Инсайдер FullRAS и Инсайдер KRAS, NRAS, BRAF', href: '', active: false},
        ],
        '/products/nomotech/insider-egfr' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'Инсайдер EGFR', href: '', active: false},
        ],
        '/products/nomotech/BRCA' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ГенТест-М BRCA1, BRCA2', href: '', active: false},
        ],
        '/products/nomotech/CYP2C9-VKORC1-CYP4F2' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ГенТест CYP2C9, VKORC1, CYP4F2', href: '', active: false},
        ],
        '/products/nomotech/CYP2C19' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ГенТест СYP2C19', href: '', active: false},
        ],
        '/products/nomotech/SLCO1B1' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ГенТест SLCO1B1', href: '', active: false},
        ],
        '/products/nomotech/CES1' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ГенТест CES1', href: '', active: false},
        ],
        '/products/nomotech/F2-F5' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ГенТест F2-F5', href: '', active: false},
        ],
        '/products/nomotech/NAT2' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ГенТест-M NAT2', href: '', active: false},
        ],
        '/products/nomotech/MGMT' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ЭпиГенТест-MGMT', href: '', active: false},
        ],
        '/products/nomotech/extract-dna2' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ЭкстрактДНК-2', href: '', active: false},
        ],
        '/products/nomotech/ffpe' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Генотипирование', href: '/products/nomotech', active: true},
          {name: 'ЭкстрактДНК FFPE', href: '', active: false},
        ],
        '/products/pcr' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '', active: false},
        ],
        '/products/pcr/bulk' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Большие фасовки (балк)', href: '', active: false},
        ],
        '/products/pcr/polymerases' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '', active: false},
        ],
        '/products/pcr/polymerases/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/pcr/polymerases/encyclo' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'Encyclo', href: '', active: false},
        ],
        '/products/pcr/polymerases/encyclo/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'Encyclo', href: '/products/pcr/polymerases/encyclo', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/pcr/polymerases/tersus' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'Tersus', href: '', active: false},
        ],
        '/products/pcr/polymerases/tersus/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'Tersus', href: '/products/pcr/polymerases/tersus', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/pcr/polymerases/snp-detect' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'SNPdetect', href: '', active: false},
        ],
        '/products/pcr/polymerases/snp-detect/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'SNPdetect', href: '/products/pcr/polymerases/snp-detect', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/pcr/polymerases/ktn-hs' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'KTN-HS', href: '', active: false},
        ],
        '/products/pcr/polymerases/taq' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'Taq', href: '', active: false},
        ],
        '/products/pcr/polymerases/taq/buffers' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'Taq', href: '/products/pcr/polymerases/taq', active: true},
          {name: 'Буферы', href: '', active: false},
        ],
        '/products/pcr/polymerases/hs-taq' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'HS Taq', href: '', active: false},
        ],
        '/products/pcr/polymerases/hs-taq/buffers' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'HS Taq', href: '/products/pcr/polymerases/hs-taq', active: true},
          {name: 'Буферы', href: '', active: false},
        ],
        '/products/pcr/polymerases/hs-taq/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'HS Taq', href: '/products/pcr/polymerases/hs-taq', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/pcr/polymerases/sybr' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'SYBR Green I', href: '', active: false},
        ],
        '/products/pcr/polymerases/rox' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'ДНК полимеразы', href: '/products/pcr/polymerases', active: true},
          {name: 'ROX', href: '', active: false},
        ],

        '/products/pcr/mixes' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '', active: false},
        ],
        '/products/pcr/mixes/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/pcr/mixes/screenmix' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X ScreenMix', href: '', active: false},
        ],
        '/products/pcr/mixes/screenmix-hs' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X ScreenMix-HS', href: '', active: false},
        ],
        '/products/pcr/mixes/screenmix-hs-udg' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X ScreenMix-HS (UDG)', href: '', active: false},
        ],
        '/products/pcr/mixes/qpcrmix-hs' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X qPCRmix-HS', href: '', active: false},
        ],
        '/products/pcr/mixes/qpcrmix-hs/citing' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X qPCRmix-HS', href: '/products/pcr/mixes/qpcrmix-hs', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/products/pcr/mixes/qpcrmix-hs-udg' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X qPCRmix-HS (UDG)', href: '', active: false},
        ],
        '/products/pcr/mixes/qpcrmix-hs-sybr' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X qPCRmix-HS SYBR', href: '', active: false},
        ],
        '/products/pcr/mixes/qpcrmix-hs-sybr-rox' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '<nobr>5X qPCRmix-HS&nbsp;с&nbsp;красителями&nbsp;SYBR&nbsp;и&nbsp;ROX</nobr>', href: '', active: false},
        ],
        '/products/pcr/mixes/qpcrmix-hs-rox' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X qPCRmix-HS с красителем ROX', href: '', active: false},
        ],
        '/products/pcr/mixes/ktnmix-hs' : [
          {name: 'Наборы и реактивы', href: '/products', active: true},
          {name: 'Полимеразы и готовые смеси', href: '/products/pcr', active: true},
          {name: 'Готовые смеси', href: '/products/pcr/mixes', active: true},
          {name: '5X KTNmix-HS', href: '', active: false},
        ],

        '/services' : [
          {name: 'Сервисы', href: '', active: false}
        ],
        '/services/pricelist' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Прайс-лист', href: '', active: false},
        ],
        '/services/synthesis' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Синтез олигонуклеотидов', href: '', active: false},
        ],
        '/services/synthesis/citing' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Синтез олигонуклеотидов', href: '/services/synthesis', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/services/synthesis/contacts' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Синтез олигонуклеотидов', href: '/services/synthesis', active: true},
          {name: 'Контакты', href: '', active: false},
        ],
        '/services/synthesis/order-form' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Синтез олигонуклеотидов', href: '/services/synthesis', active: true},
          {name: 'Заказ On-line', href: '', active: false},
        ],
        '/services/sequencing/sanger' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Секвенирование по Сэнгеру', href: '', active: false},
        ],
        '/services/sequencing/sanger/standard-primers' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Секвенирование по Сэнгеру', href: '/services/sequencing/sanger', active: true},
          {name: 'Стандартные праймеры', href: '', active: false},
        ],
        '/services/sequencing/sanger/citing' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Секвенирование по Сэнгеру', href: '/services/sequencing/sanger', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/services/sequencing/sanger/contacts' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Секвенирование по Сэнгеру', href: '/services/sequencing/sanger', active: true},
          {name: 'Контакты', href: '', active: false},
        ],
        '/services/ges' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Генная инженерия', href: '', active: false},
        ],
        '/services/ges/contacts' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Генная инженерия', href: '/services/ges', active: true},
          {name: 'Контакты', href: '', active: false},
        ],
        '/services/ges/gene-synthesis' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Генная инженерия', href: '/services/ges', active: true},
          {name: 'Синтез генов', href: '', active: false},
        ],
        '/services/ges/gene-synthesis/citing' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Генная инженерия', href: '/services/ges', active: true},
          {name: 'Синтез генов', href: '/services/ges/gene-synthesis', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/services/ges/mutagenesis' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Генная инженерия', href: '/services/ges', active: true},
          {name: 'Сайт-направленный мутагенез', href: '', active: false},
        ],
        '/services/ges/mutagenesis/citing' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Генная инженерия', href: '/services/ges', active: true},
          {name: 'Сайт-направленный мутагенез', href: '/services/ges/mutagenesis', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/services/ges/pcr-cloning' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Генная инженерия', href: '/services/ges', active: true},
          {name: 'Амплификация и клонирование фрагментов ДНК', href: '', active: false},
        ],
        '/services/ges/amplicon-cloning' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Генная инженерия', href: '/services/ges', active: true},
          {name: 'Клонирование ПЦР-продуктов', href: '', active: false},
        ],
        '/services/chemistry' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Химический синтез', href: '/services/chemistry', active: false},
        ],
        '/services/chemistry/contacts' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Химический синтез', href: '/services/chemistry', active: true},
          {name: 'Контакты', href: '', active: false},
        ],
        '/services/chemistry/organic-synthesis' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Химический синтез', href: '/services/chemistry', active: true},
          {name: 'Синтез органических соединений', href: '', active: false},
        ],
        '/services/chemistry/development' : [
          {name: 'Сервисы', href: '/services', active: true},
          {name: 'Химический синтез', href: '/services/chemistry', active: true},
          {name: 'Разработка полупромышленных технологий синтеза', href: '', active: false},
        ],
        '/support' : [
          {name: 'Техническая информация', href: '', active: false}
        ],
        '/support/publications' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Публикации', href: '', active: false},
        ],
        '/support/citing' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Цитирование', href: '', active: false},
        ],
        '/support/technologies' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '', active: false},
        ],
        '/support/technologies/normalization' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Нормализация кДНК', href: '', active: false},
        ],
        '/support/technologies/genome-walking' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Прогулка по хромосоме', href: '', active: false},
        ],
        '/support/technologies/mint-cdna-synthesis' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Mint', href: '', active: false},
        ],
        '/support/technologies/smart' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'SMART', href: '', active: false},
        ],
        '/support/technologies/mos' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'MOS', href: '', active: false},
        ],
        '/support/technologies/ssh' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'SSH', href: '', active: false},
        ],
        '/support/technologies/long-product-amplification' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Амплификация длинной фракции кДНК', href: '', active: false},
        ],
        '/support/technologies/step-out-race' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Step-Out RACE', href: '', active: false},
        ],
        '/support/technologies/wtb-pcr' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'WTB-PCR', href: '', active: false},
        ],
        '/support/technologies/rna-electrophoresis' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Электрофорез РНК', href: '', active: false},
        ],
        '/support/technologies/rna-isolation' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Выделение РНК', href: '', active: false},
        ],
        '/support/technologies/plasmid-purification' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Очистка плазмид', href: '', active: false},
        ],
        '/support/technologies/blood-preparation' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Методы и протоколы', href: '/support/technologies', active: true},
          {name: 'Первичная обработка цельной крови', href: '', active: false},
        ],
        '/support/literature' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Презентационная литература', href: '', active: false},
        ],
        '/support/license-statements' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Лицензионные соглашения', href: '', active: false},
        ],
        '/support/license-statements/evrogen-fp-license' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Лицензионные соглашения', href: '/support/license-statements', active: true},
          {name: 'Ограниченная лицензия #001', href: '', active: false},
        ],
        '/support/license-statements/evrogen-dsn-license' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Лицензионные соглашения', href: '/support/license-statements', active: true},
          {name: 'Ограниченная лицензия #002', href: '', active: false},
        ],
        '/support/license-statements/invitrogen-gateway-license' : [
          {name: 'Техническая информация', href: '/support', active: true},
          {name: 'Лицензионные соглашения', href: '/support/license-statements', active: true},
          {name: 'Лицензия компании Инвитроген No 19', href: '', active: false},
        ],



      }
    }
  }
}
