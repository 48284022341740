import bus from './bus'
import _ from 'lodash'

export default function Primer(params) {
  params = params || {}
  this.id = typeof params.id !== 'undefined' ? params.id : NaN
  this.name = typeof params.name !== 'undefined' ? params.name : ''
  // this.price = NaN
  this.price = 0
  // this.discount_price = NaN
  this.discount_price = 0
  this.mods = typeof params.mods !== 'undefined' ? params.mods : []
  this.scale = typeof params.scale !== 'undefined' ? params.scale : ''
  this.options = typeof params.options !== 'undefined' ? params.options : []
  this.sequence = typeof params.sequence !== 'undefined' ? params.sequence : ''
  this.numberInOrder = typeof params.numberInOrder !== 'undefined' ? params.numberInOrder : 1
  this.editState = typeof params.editState !== 'undefined' ? params.editState : 'draft'
  this.primerIsActive = false

  this.forDelete = false
  this.priority = 0
  this.state = 'ok'

  this.cursorPosition = 0
  this.selected = false
}

function buildScaleDict(src, calcFn, defaultValue) {
  let split = src.trim().split(/;|,|\s/g).map(s => s.trim());
  return split.length === 4
    ? {
      '0.02':calcFn(split[0]),
      '0.04':calcFn(split[1]),
      '0.2': calcFn(split[2]),
      '1': calcFn(split[3])
    }
    :{ '0.02': defaultValue, '0.04': defaultValue, '0.2': defaultValue, '1': defaultValue};
}




Primer.initStaticFields = function (dictionaries) {

  Primer.allModifiers = dictionaries.modifiers;

  Primer.maxLength = buildScaleDict(dictionaries.settings['primerMaxLength'], val => val.trim() * 1 || 145, 145);

  Primer.minLength = dictionaries.settings['primerMinLength'] * 1;

  Primer.canCleanObj = buildScaleDict(dictionaries.settings['canPrimerClean'], val => val.trim() === '+', false);

  Primer.canKeenObj = buildScaleDict(dictionaries.settings['canPrimerKeen'], val => val.trim() === '+', false);

  Primer.canAsteriskObj = buildScaleDict(dictionaries.settings['canPrimerAsterisk'], val => val.trim() === '+', false);




  // Если при экспорте вставляют bhq то надо будет заменять на dt-bhq
  Primer.bhqForReplaceIfInner = [
    {from: 10, to: _.find(Primer.allModifiers, mod => mod.id * 1 === 15)},
    {from: 11, to: _.find(Primer.allModifiers, mod => mod.id * 1 === 16)}
  ]

  // Если эти праймеры на 5` очистка не обязательна
  Primer.modifiersWoClean = [12, 13, 14]

  Primer.degenArraySorted = [['ACGT', 'N'], ['AGT', 'D'], ['ACT', 'H'], ['ACG', 'V'], ['AC', 'M'], ['AG', 'R'], ['AT', 'W'],
    ['CG', 'S'], ['CT', 'Y'], ['GT', 'K'], ['TGC', 'B']].map(function (item) {
    return [item[0].split('').sort().join(''), item[1]]
  })

  Primer.softAcceptedRx = new RegExp(
    '(\\([A-Z]\\))|(' + Primer.degenArraySorted.reduce(function (acc, item) {
      return acc + item[1]
    }, `[^${letters}`) + ']{1})',
    'gi')
  Primer.hardAcceptedRxString = Primer.degenArraySorted.reduce(function (acc, item) {
    return acc + item[1]
  }, `[^${letters}`) + ']{1}'
  Primer.hardAcceptedRx = new RegExp(Primer.degenArraySorted.reduce(function (acc, item) {
    return acc + item[1]
  }, `[^${letters}`) + ']{1}', 'gi')

  const aliases = Primer.allModifiers.reduce((acc, mod) => {
    acc.push(...mod.aliases.map(item => item.replace(/-/g, '').toUpperCase()))
    return acc
  }, [])
  Primer.modifierNamesSorted = aliases.sort(function (a, b) {
    return a.length > b.length ? -1 : 1
  })
  const rx = Primer.modifierNamesSorted.reduce(function (acc, item) {
    acc += acc === '' ? '' : '|'
    acc += '(' + item + ')|(-\\(' + item + '\\)-)|(\\(' + item + '\\))'
    return acc
  }, '')
  Primer.rxForPrimerParse = new RegExp(rx, 'gi')
  const checkErrorModifiers =
    `([(\\[/-]([^)\\/]+(${rx}))[)\\]/-])|` +
    `([(\\[/-]((${rx})[^)\\/]+)[)\\]/-])|` +
    `([(\\[/-]([^)\\/]+(${rx})[^)\\/]+)[)\\]/-])`
  Primer.rxCheckErrorModifiers = new RegExp(checkErrorModifiers, 'ig')

  // Primer.rxParseRemoveLetters = new RegExp('[^a-zа-я0-9\\\\\\/|_)(}{\\][\'":;-]', 'gi')
  Primer.rxParseRemoveLetters = /\s/gi
}

Primer.getMaxLength = function(scale) {
  return Primer.maxLength[scale ||''] || _.max(Object.entries(Primer.maxLength).map(el => el[1]));
}

Primer.canClean = function(scale) {
  const result = Primer.canCleanObj[scale];
  if (typeof result === 'undefined') {
    return true;
  }
  return Primer.canCleanObj[scale];
}

Primer.canKeen = function(scale, primer) {

 if (primer && primer.mods?.some(rl => primer.getModPositionName(rl) === 'left' )) {
   return false;
 }

  return Primer.canKeenObj[scale];
}

Primer.canAsterisk = function(scale) {
  return Primer.canAsteriskObj[scale];
}




Primer.prototype.toString = function () {
  return this.$myHelper.syntaxHighlight(JSON.stringify(this, undefined, 4))
}

const enumPosition = {left: 0, equal: 1, right: 2}
const letters = 'ACGTIU*'

Primer.replaceRuAndBadCharsInString = function (src) {
  let result = src.replace(Primer.rxParseRemoveLetters, '')

// eslint-disable-next-line no-useless-escape
  const ruChars = 'А|С|Т|М|Н|К|В|Х|\[|\{|\]|\}|<|>|\!|\,'
  // eslint-disable-next-line no-useless-escape
  const enChars = 'A|C|T|M|H|K|B|X|\(|\(|\)|\)|(|)'
  result = result.replace(new RegExp(ruChars, 'gi'), function (char) {
    const idx = ruChars.indexOf(char.toUpperCase())
    return idx >= enChars.length ? '' : enChars[ruChars.indexOf(char.toUpperCase())]
  })
  return result.replace(/\(+/g, '(').replace(/\)+/g, ')')
}

Primer.findDegenerateChar = function (srcChars) {
  srcChars = srcChars.replace(/[)(/\\-]/g, '')                                                             // В srcChars могут быть скобки или дефисы
  const fndChars = srcChars.split('').sort().join('')                                                        // Сортируем побуквено что бы найти совпадение в degenArraySorted
  const fndEl = _.find(Primer.degenArraySorted, item => item[0] === fndChars)
  // Если есть совпадени то возвращаем букву
  return fndEl ? fndEl[1] : null                                                                          // иначе - то что было
}

Primer.replaceDegenerateCharsInString = function (src) {
  src = src.replace(/-/g, '--')
  // что бы регэкс спрвился с ситуацией mod-mod
  src = src.replace(/(\(([A-Z]+)\))/g, function (match) {
    const result = Primer.findDegenerateChar(match)
    return result || match
  })
  return src.replace(/-/g, '')
}

Primer.prototype.lengthWithMods = function () {
  return this.sequence.length + (this.mods ? this.mods.length : 0)
}
Primer.prototype.lengthOnlyChars = function () {
  return this.sequence.replace(/\*/g, '').length
}




Primer.prototype.replaceDegenerateCharsInPrimer = function () {
  const rx = new RegExp('\\([A-Z]{2,4}\\)', 'gi')
  let ptr = NaN
  let oldCharsLength = NaN

  this.sequence = this.sequence.replace(rx, function (find, index) {
    const fndChar = Primer.findDegenerateChar(find)
    if (fndChar) {
      ptr = index
      oldCharsLength = find.length
      return fndChar
    }
    return find
  })

  if (!isNaN(ptr)) {
    const modLeftCursor = this.getModsRelCursor(enumPosition.left).length   // Запоминаем сколько модификаторов левее курсора сдесь т.к. следующий оператор
    // может поменять это значение
    this.getModsRelCursor(enumPosition.right).forEach(function (i) {
      i.position -= oldCharsLength - 1                                    // Количество позиций на которое надо сдвинуть влево все модификаторы
    })
    this.cursorPosition = ptr + modLeftCursor + 1
  }
}

Primer.checkErrorModifiersAndThrowEx = function (src) {
  let fnd

  Primer.rxCheckErrorModifiers.lastIndex = 0

  while (fnd = Primer.rxCheckErrorModifiers.exec(src.replace(/[ -]/g, ''))) {
    const fndValue = (fnd[1] || fnd[0]).replace(/[)(/]/g, '')

    if (Primer.modifierNamesSorted.indexOf(fndValue.toUpperCase()) === -1) {
      throw  new Error('Недопустимый модификатор: \'' + fndValue + '\'')
    }

  }

  return ''
}

Primer.parsePrimerString = function (src, noUseAlias = false) {
  let savePtr = 0
  let fnd
  const resultPrimer = new Primer()

  while (fnd = Primer.rxForPrimerParse.exec(src)) {
    if (savePtr < fnd.index) {
      resultPrimer.sequence += src.substring(savePtr, fnd.index)
    }
    //В истории заказов нужно парсить удаленные модификаторы - парсим по точному совпадению названий, не смотрим на алиасы
    const fndModifier = noUseAlias
       ? Primer.allModifiers.find(mod => mod.name.trim().toLowerCase().replace(/-/g, '') === fnd[0].toLowerCase().replace(/[)(]/g, ''))
       : Primer.allModifiers.find(mod =>
               (!mod.isDeleted) && mod.aliases.some(al => al.trim().toLowerCase().replace(/-/g, '') === fnd[0].toLowerCase().replace(/[)(]/g, ''))
    );
    if (fndModifier) {
      resultPrimer.addModifier(fndModifier, resultPrimer.sequence.length + resultPrimer.mods.length)
    }
    savePtr = fnd.index + fnd[0].length
  }

  if (savePtr < src.length) {
    resultPrimer.sequence += src.substring(savePtr)
  }

  return resultPrimer
}
Primer.parse = function (src, withException, noUseAlias) {
  src = Primer.replaceRuAndBadCharsInString(src)
  withException && Primer.checkErrorModifiersAndThrowEx(src)
  src = src.toUpperCase()
  src = src.replace(/[\\/ ]/g, '')                                                                          // Дефисы и скобки нас больше не интересуют
  src = Primer.replaceDegenerateCharsInString(src)

  const resultPrimer = Primer.parsePrimerString(src, noUseAlias)
  Primer.replaceInnerModifiersIfNeed(resultPrimer)

  return resultPrimer
}
Primer.replaceInnerModifiersIfNeed = function (primer) {
  const primerRightPosition = primer.lengthWithMods() - 1
  const innerMods = _.filter(primer.mods, mod =>
    mod.position > 0 &&
    mod.position < primerRightPosition &&
    _.findIndex(Primer.bhqForReplaceIfInner, item => item.from * 1 === mod.mod.id * 1) !== -1
  )
  innerMods.forEach(mod => {
    const idx = primer.mods.indexOf(mod)
    const newMod = _.find(Primer.bhqForReplaceIfInner, item => item.from * 1 === mod.mod.id * 1).to
    primer.mods[idx].mod = newMod
  })
}

// Возвращает массив модификаторов слева или справа от курсора
Primer.prototype.getModsRelCursor = function (position, cursorPosition) {
  cursorPosition = typeof cursorPosition !== 'undefined' ? cursorPosition : this.cursorPosition
  const ops = {
    0: function (a, b) {
      return a < b
    },
    1: function (a, b) {
      return a === b
    },
    2: function (a, b) {
      return a >= b
    }
  }
  return this.mods.filter(function (i) {
    return ops[position](i.position, cursorPosition)
  })
}

Primer.prototype.setCursor = function (cursor) {
  this.cursorPosition = cursor.position
}

Primer.prototype.buildHtml = function (mode, withTriplets) {
  mode = mode || 'image'
  withTriplets = (typeof withTriplets === 'undefined') ? false : withTriplets

  if (this.mods.length === 0) {
    return withTriplets ?
      this.buildTriplets(this.buildHtmlPart(this.sequence))
      : this.buildHtmlPart(this.sequence)
  }

  let savePtr = 0
  let modCount = 0

  const sortedMods = _.sortBy(this.mods, function (mod) {
    return mod.position
  }) // Используется сортировка из lodash т.к. стандартная козлит в ie а разбираться лень

  const result = sortedMods.reduce((html, modifier, index, array) => {
    if (savePtr < modifier.position) {
      html += this.buildHtmlPart(this.sequence.substring(savePtr - modCount, modifier.position - modCount))
    }

    html += this.buildModifierView(modifier, mode)

    savePtr = modifier.position + 1
    modCount++

    if (index === array.length - 1 && savePtr <= this.sequence.length + modCount) {
      html += this.buildHtmlPart(this.sequence.substring(savePtr - modCount))
    }

    return html

  }, '')

  return withTriplets
    ? this.buildTriplets(result)
    : result

}

Primer.prototype.buildTriplets = function (src) {
  const rx = /((([a-z])|(<span class='primer-error-letter'>[^<]+<\/span>)){1,3})|(<span[^>]*>[^<]+<\/span>)|(<img[^>]*>)/ig
  return src.replace(rx, function (var1) {
    return var1 + ' '
  })
}

Primer.prototype.buildHtmlPart = function (src) {
  const fn = match => "<span class='primer-error-letter'>" + match + '</span>';
  let result= src.replace(Primer.softAcceptedRx, fn);
  return Primer.canAsterisk(this.scale)
    ? result.replace(/\*{2,}/gi, fn)
    : result.replace(/\*/gi, fn)
}

Primer.prototype.buildModifierView = function (modifier, mode) {
  let position = this.getModPositionName(modifier)
  const primerLen = this.lengthWithMods()
  const textView = this.buildModifierTextView(modifier, position, primerLen)

  if (mode === 'text') {
    return textView
  } else if (mode !== 'image') {
    const errPfx = this.modifierIsError(modifier) ? ' modificator_error' : ''
    return `<span class='primer-modifier-div${errPfx}'>${textView}</span>`
  }

  if (primerLen === 1) {
    position = 'menu'
  }

  const errPfx = this.modifierIsError(modifier) ? '_err' : ''
  const edtAtrr = isIeOrFF() ? "contentEditable='false'" : ''    // в FF запрещает изменение картинки, В ie делает курсор над картинкой default а в других мешает ходить курсору

  return `<img alt='${textView}' unselectable='on' ` +
    `style='margin-top:-2px; cursor:default; vertical-align:middle' ` +
    `onclick='setCursorNearImage(event, ${modifier.position})' ` +
    `src='/modsImages/${modifier.mod.name}_${position}${errPfx}.png' ` +
    `${edtAtrr} >`

}

function isIeOrFF() {
  return document.documentMode || /Edge/.test(navigator.userAgent)      // IE | edge
    || navigator.userAgent.toLowerCase().indexOf('firefox') > -1  // FF
}

Primer.setCursorNearImage = function (event, position) {
  const x = event.offsetX ? (event.offsetX) : event.pageX - document.getElementById('pointer_div').offsetLeft
  position += (x > event.target.width / 2) ? 1 : 0

  bus.$emit('click-editor-modifier', position)
}

Primer.prototype.buildModifierTextView = function (modifier, position, primerLen) {
  let result = position !== 'left' && primerLen > 1 ? '-' : ''
  result += '/' + modifier.mod.name + '/'
  return result + (position !== 'right' && primerLen ? '-' : '')
}

// todo: вставка на место выделения
Primer.prototype.insertCharOrPart = function (src) {
  const partPrimer = src.length > 1 ? Primer.parse(src) : null

  // Курсор показывает позицию с учетом модификаторов, а вставлять надо в строку где модификаторов нет - корректируем позицию
  const leftModsCount = this.mods.filter(i => i.position < this.cursorPosition).length
  this.sequence = this.sequence.substring(0, this.cursorPosition - leftModsCount)
    + (partPrimer ? partPrimer.sequence : src)
    + this.sequence.substring(this.cursorPosition - leftModsCount)
  // Сдвигаем правее те модификаторы которые уже есть
  this.getModsRelCursor(enumPosition.right).forEach(function (i) {
    i.position += partPrimer ? partPrimer.lengthWithMods() : 1
  })
  // Добавляем новые
  if (partPrimer) {
    partPrimer.mods.forEach(srcMod => {
      srcMod.position += this.cursorPosition
      this.mods.push(srcMod)
    })
  }
  this.cursorPosition += partPrimer ? partPrimer.lengthWithMods() : 1
}

Primer.prototype.deleteChar = function (positionAndSelect, isBackSpace) {
  if (isBackSpace && this.cursorPosition === 0) {
    return
  }

  isBackSpace = positionAndSelect.select > 1 ? true : isBackSpace

  const backSpaceCorrection = isBackSpace ? 1 : 0

  const modInCursorPosition = this.getModsRelCursor(enumPosition.equal, this.cursorPosition - backSpaceCorrection)
  if (modInCursorPosition.length > 0) {
    this.mods.splice(this.mods.indexOf(modInCursorPosition[0]), 1)
  } else {
    const leftModsCount = this.getModsRelCursor(enumPosition.left).length
    this.sequence = this.sequence.substring(0, this.cursorPosition - leftModsCount - backSpaceCorrection)
      + this.sequence.substring(this.cursorPosition - leftModsCount + 1 - backSpaceCorrection)
  }
  this.getModsRelCursor(enumPosition.right, this.cursorPosition - backSpaceCorrection).forEach(function (i) {
    i.position--
  })
  this.cursorPosition = positionAndSelect.position - isBackSpace
  if (positionAndSelect.select > 1) {
    this.deleteChar({position: this.cursorPosition, select: positionAndSelect.select - 1}, isBackSpace)
  }
}

Primer.prototype.addModifier = function (modifier, position) {
  position = typeof position !== 'undefined' ? position : this.cursorPosition

  this.getModsRelCursor(enumPosition.right, position).forEach(function (i) {
    i.position++
  })
  this.cursorPosition = position + 1
  this.mods.push({position: position, mod: modifier})
}

Primer.prototype.removeOptionsIfExist = function (optionsName) {
  const ptr = this.options.indexOf(optionsName)
  if (ptr >= 0) {
    this.options.splice(ptr, 1)
  }
}

Primer.prototype.setOptions = function (optionsName, isSet) {
  if (!isSet) {
    this.removeOptionsIfExist(optionsName)
    return
  }
  if (this.options.indexOf(optionsName) < 0) {
    this.options.push(optionsName)
  }
  if (optionsName === 'clean') {
    this.removeOptionsIfExist('op')
  }
  if (optionsName === 'op') {
    this.removeOptionsIfExist('clean')
  }
}

Primer.prototype.isErrors = function () {
  return this.errorMessages().length > 0
}

Primer.prototype.canCalcPrice = function () {
  return !this.errorMessages().some(function (item) {
    return ['char', 'modifier', 'scale'].indexOf(item.code) >= 0
  })
}

Primer.prototype.errorMessages = function () {
  const result = []

  if (!this.name) {
    result.push({code: 'name', message: 'Не задано название'})
  }
  const primerLength = this.lengthWithMods()
  if (primerLength === 0) {
    result.push({code: 'empty', message: 'Пустой праймер'})
  }

  let maxLength = Primer.getMaxLength(this.scale);
  if (primerLength > maxLength) {
    result.push({code: 'maxLenght', message: `Максимальная длина последовательности: ${maxLength} нт`})
  }

  if (primerLength < Primer.minLength) {
    result.push({code: 'minLenght', message: `Минимальная длина последовательности: ${Primer.minLength} нт`})
  }

  const rx = new RegExp(Primer.hardAcceptedRxString, 'gi')
  if (rx.test(this.sequence)) {
    result.push({code: 'char', message: 'Недопустимые символы в праймере (подсвечены красным)'})
  }
  if (this.mods.some(i => !i.mod.disabled && this.modifierIsError(i))) {
    result.push({code: 'modifier', message: 'Ошибочная позиция (или комбинация) модификаторов (подсвечены красным)'})
  }
  if (this.mods.some(i => i.mod.disabled)) {
    result.push({code: 'modifier', message: 'Модификатор запрещен (подсвечены красным)'})
  }
  if (!Primer.canAsterisk(this.scale) && this.sequence.includes('*') ) {
    result.push({code: 'asterisk', message: `Символ '*' запрещен для шкалы ${this.scale}` })
  }
  if (Primer.canAsterisk(this.scale) && this.sequence.includes('**') ) {
    result.push({code: 'asterisk', message: `Символы '*' не могут рядом стоять` })
  }





  if (this.scale === '') {
    result.push({code: 'scale', message: 'Не задана шкала'})
  }
  if (this.scale * 1 === 0.02 && this.mods.length > 0) {
    result.push({code: 'scale002', message: 'Для модифицированных праймеров не доступна шкала 0.02'})
  }

  // модифицированные праймеры по шкале 1 нельзя.  Исключение - праймеры с единственным модификатором на 5` если этот модификатор - (Alk, Biotin, NH2, Pi)
  if (this.scale * 1 === 1 && this.mods.some(m => m.position !== 0 || ![12, 13, 14, 17].includes(m.mod.id * 1))) {
    result.push({code: 'scale1', message: 'Для этого праймера доступная шкала 0.04 или 0.2'})
  }
  return result
}

Primer.prototype.modifierIsError = function (modifier) {
  const modPosName = this.getModPositionName(modifier)
  // Проверяем что модификатор стоит где можно
  if (!modifier.mod.acceptedPositionArray.some(function (i) {
    return i === modPosName
  })) {
    return true
  }
  //Исключить возможность заказа:
  //А) Олигонуклеотид + внутренний блэкхол (dTBHQ) + любой 5’-модификатор, кроме 6-FAM,  фосфат, биотин, алкин. 3’-конец при этом может быть любым.
  const errorIds = [22, 14, 13, 17];
  if ( modPosName === 'left'
    && !errorIds.includes(modifier.mod.id * 1)
    && this.mods.some(m => this.getModPositionName(m) === 'in')) {
    return true;
  }
  // Сочетание с двух сторон двух РАЗНЫХ элементов из списка: Cy3, Cy5, HEX, JOE, R6G, ROX, TAMRA, TET, FAM.
  // При этом допустить возможность заказа FAM-0000000000-X (FAM - id:22),
  // Где Х – любой элемент из списка выше. При этом допустить возможность заказа ОДИНАКОВЫХ элементов из
  // списка выше с 5’- и 3’-концов (например CY3-0000000000-CY3)
  if ( modPosName === 'left'
       && (modifier.mod.type === 'fluorophore' || [10, 11].includes(modifier.mod.id * 1))
       && modifier.mod.id *1 !== 22
       && this.mods.some(m => this.getModPositionName(m) === 'right'
                              && (m.mod.type === 'fluorophore' && ![10, 11].includes(m.mod.id * 1))
                              && m.mod.id * 1 !== modifier.mod.id * 1)) {
      return true;
  }
  if ( modPosName === 'right'
    && (modifier.mod.type === 'fluorophore' )
    && this.mods.some(m => this.getModPositionName(m) === 'left'
                           && ![22, 10, 11].includes(m.mod.id * 1)
                           && m.mod.type === 'fluorophore'
                           && m.mod.id * 1 !== modifier.mod.id * 1)) {
    return true;
  }
  return false
}

Primer.prototype.isForceClean = function () {
  return this.mods.length === 0
    ? false
    : this.mods.some(rl =>
                   (this.getModPositionName(rl) === 'left' && rl.mod.needCleanIn5Position) ||
                   (this.getModPositionName(rl) === 'right' && rl.mod.needCleanIn3Position) ||
                   (this.getModPositionName(rl) === 'in' && rl.mod.needCleanInInPosition));
}

Primer.prototype.getModPositionName = function (modifier) {
  return modifier.position === 0
    ? 'left'
    : (modifier.position === this.lengthWithMods() - 1
      ? 'right'
      : 'in')
}

Primer.prototype.lastCharIsUorI = function() {
  if (this.sequence.length  === 0 || this.mods.some(rl => this.getModPositionName(rl) === 'right' )) {
    return false;
  }
  const lastChar = this.sequence[this.sequence.length - 1].toUpperCase();
  return ['U','I'].includes(lastChar);
}

