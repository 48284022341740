import discount from "../../pages/profile/Discount.vue";

let calcDiscountPrice = (sum, discountPercent) => {
  return sum - Math.floor(sum / 100 * discountPercent)
}

export default {
  counters(state, getters, rootState){
    let result = { sum: 0, count: 0 }

    let pdDiscount = state.pdDiscount ||  rootState.user.user.discountInfo ? (rootState.user.user.discountInfo.pdPercent || 0) * 1 : 0
    let seqDiscount = state.seqDiscount // || rootState.user.user.discountInfo ? (rootState.user.user.discountInfo.sequenceDiscount || 0) * 1 : 0
    let primerDiscount = state.primerDiscount
    if (state.basket && state.basket.products) {
      result = state.basket.products.reduce(
        (add, item) =>
          (item.forDelete
            ? add
            : {
              count: add.count * 1 + item.count * 1,
              sum: add.sum * 1 + item.discount_count * calcDiscountPrice(item.discount_price * 1, pdDiscount)
                + calcDiscountPrice(item.price * 1, pdDiscount) * (item.count * 1 - item.discount_count * 1)
            })
        , { sum: 0, count: 0 },
      )
    }

    if (state.basket.primers && state.basket.primers.items) {
      result.count += state.basket.primers.items.filter(item => (!item.forDelete) && item.editState === 'basket').length
      result.sum += state.basket.primers.items.reduce((add, item) => add + ((!item.forDelete) && item.editState === 'basket' ? item.discount_price * 1 : 0), 0)
    }
    let seqSum = getters.sequenceSum(seqDiscount).discountSum;
    if (seqSum ) {
      result.count ++;
    }
    result.sum += seqSum;
    result.sum += getters.sequencePrimersSum(primerDiscount)
    return result
  },

  sequenceSummary: (state, getters, rootState) => (discount = 0) => {
    if (!state.basket?.sequences) {
      return [];
    }

    const prices = rootState.dictionaries.sequencePrices;
    const options = rootState.dictionaries.sequenceOptions;

    const calcDiscount = price => (price - Math.round(price / 100 * (discount) * 100) / 100);

    const result = state.basket.sequences.elements
    .filter(fr => fr.option && fr.option !== 'none')
    .reduce((acc, fr) => {
      let fndItem = acc.find(i => i.key === fr.option)
      if (!fndItem) {
        fndItem = {
          key: fr.option,
          name: options[ fr.option ],
          sum: 0,
          discountSum: 0,
          count: 0,
          price: prices[ fr.option ],
          cat: prices[`${fr.option}Cat`],
          discountPrice: prices[ fr.option ]
        }
        acc.push(fndItem)
      }
      fndItem.sum += prices[ fr.option ]
      fndItem.discountSum += prices[ fr.option ]
      fndItem.count++
      return acc
    }, [])

    result.push({
                  key: 'fragments',
                  name: 'Образцы',
                  count: state.basket.sequences.elements.length,
                  sum: 0,
                  discountSum: 0,
                  price: 0,
                  discountPrice: 0
                })
    result.push({
                  key: 'primers',
                  name: 'Праймеры',
                  count: state.basket.sequences.primers?.length,
                  sum: 0,
                  discountSum: 0,
                  price: 0,
                  discountPrice: 0
                })

    let count = state.basket.sequences.elements?.filter(fr => fr.action === 'fragmentAnalyze')?.length || 0
    result.push({
                  key: 'fragmentAnalyze',
                  name: 'Фрагментный анализ',
                  count,
                  sum: count * prices.fragmentAnalyze,
                  discountSum: count * calcDiscount(prices.fragmentAnalyze),
                  price: prices.fragmentAnalyze,
                  cat: prices.fragmentAnalyzeCat,
                  discountPrice: calcDiscount(prices.fragmentAnalyze)
                })

    result.push(Object.assign(
      state.basket.sequences.elements
      ?.filter(fr => fr.action !== 'fragmentAnalyze')
      ?.reduce((acc, fr) => {
        const count = fr.primersRls.length
        acc.count += count
        acc.sum += prices.sequence * count
        acc.discountSum += calcDiscount(prices.sequence) * count
        return acc }, {count: 0, sum: 0, discountSum: 0})
      || {count: 0, sum: 0, discountSum: 0},
      {
        key: 'sequence',
        name: 'Секвенирование',
        price: prices.sequence,
        cat: prices.sequenceCat,
        discountPrice: calcDiscount(prices.sequence)
      }
    ))
    count = state.basket.sequences.elements.filter(el => el.withPcr).length
    result.push({
                  key: 'pcr',
                  name: 'ПЦР',
                  count,
                  sum: prices.pcr * count,
                  discountSum: prices.pcr * count,
                  price: prices.pcr,
                  cat: prices.pcrCat,
                  discountPrice: prices.pcr
                })
    count = state.basket.sequences.dnaExtractCount || 0;
    result.push({
                  key: 'dnaExtract',
                  name: 'Выделение геномной ДНК',
                  count,
                  sum: prices.dnaExtract * count || 0,
                  discountSum: prices.dnaExtract * count || 0,
                  price: prices.dnaExtract,
                  cat: prices.dnaExtractCat,
                  discountPrice: prices.dnaExtract
                });
    return result.filter(el => el.count > 0 && el.price > 0);
  },

  sequenceSum: (state, getters) => (discount = 0) => {
    let result = getters.sequenceSummary(discount)
    .filter(row => row.count > 0 && row.discountSum > 0)
    .reduce((acc, row) => ({sum: acc.sum + row.sum, discountSum: + acc.discountSum + row.discountSum}), {sum: 0, discountSum: 0});
    return result;
  },

  sequencePrimersSum: (state, getters, rootState ) => (discount) => {
    return getters.primersForSequence
    .reduce((acc, primer) => acc + getters.calcSeqPrimer(primer, discount).discountPrice, 0 ) || 0
  },

  calcSeqPrimer: (state, getters, rootState) => (primer, discount) => {
    const prices = rootState.dictionaries.primerPrices[primer.scale];
    const discountCharPrice = prices.char - prices.char / 100 * (discount || 0);
    const chArray = [...(primer.sequence.toLowerCase())];
    const price =
      chArray.filter(char => !['i','u'].includes(char)).length * prices.char +
      chArray.filter(char => char === 'i').length * prices.inozin +
      chArray.filter(char => char === 'u').length * prices.uridin;
    const discountPrice =
      chArray.filter(char => !['i','u'].includes(char)).length * discountCharPrice  +
      chArray.filter(char => char === 'i').length * prices.inozin +
      chArray.filter(char => char === 'u').length * prices.uridin;

    return {
      price,
      discountPrice
    }







  },


  primersForSequence(state) {
    return state.basket.sequences?.primers?.filter(primer => primer.storeType === 'fromOrder') || [];
  }

}
